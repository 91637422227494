/**
 * Método submete um . 
 * 
 * @param {*} form 
 * @param {*} sucessCallback 
 * @param {*} errorCallback 
 * @param {OPTIONAL} funcaoShowLoader -  
 * @param {OPTIONAL} funcaoHideLoader -  
 * @returns 
 */
 Eny.postForm = function(form, sucessCallback, errorCallback) {
    var showLoading = hideLoading = function(){};
    if(arguments[3]){
        showLoading = arguments[3];
    }
    if(arguments[4]){
        hideLoading = arguments[4];
    }
    
    var formData = new FormData($(form)[0]);
    $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: formData,
        beforeSend: function() {
          showLoading();
        },
        success: function(msg, req, t) {
            sucessCallback(msg);
            hideLoading(true, msg);         
        },
        error: function(msg) {
            errorCallback(msg.responseText, msg);
            hideLoading(false, msg);
        },
        cache: false,
        contentType: false,
        processData: false
    });
    return false;
}