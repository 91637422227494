/**
 * 
 * @param {Date} data 
 * @returns 
 */
Eny.tempoCompletoExtenso = function(data) {

    var day = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"][data.getDay()];
    var date = data.getDate();
    var month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][data.getMonth()];
    var year = data.getFullYear();
    var minute = data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes();
    var second = data.getSeconds() < 10 ? "0" + data.getSeconds() : data.getSeconds();
    var hora = data.getHours() + ':' + minute + ":" + data.getSeconds();
    return day + "," + date + " de " + month + " de " + year + " às " + hora;
}