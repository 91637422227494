/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var ID_MESSAGE = 1;

/**
 * Atribui por padrão uma caixa de mensagem na div com id=main-content 
 * caso não exista essa div e não senha setada na div
 * 
 * @param {String} mensagem
 * @returns {undefined}
 */
var enyMessage = ENYMessage = function (mensagem){
    var args = ['mensagem', 'tipo', 'tempo', 'container'];
    this.tipo = 'success';
    this.container = '#main-content';   //TODO verificar se possuir mais de um container na tela.  
    for (var i = 1; i < arguments.length; i++) {
        console.log(args[i])
       this[args[i]] = arguments[i];
    }
    this.html = 
            '<div id="mensagem' + ID_MESSAGE + '" class="alert alert-' + tipo + ' alert-dismissible fade show" role="alert">'
            +'  <button type="button" class="close" data-dismiss="alert" aria-label="Fechar"><span aria-hidden="true">&times;</span></button>'
            +'  <p>' + mensagem + '</p>'
            +'</div>';    
     exec();
    
    //##### Métodos ####
    function addContainer(){
        if(ID_MESSAGE === 1){
            $(container).prepend('<div id="EnyaliusMessageContainer"></div>');
        }
    }
    
    function exec(){
        addContainer();
        $("#EnyaliusMessageContainer").append(this.html);
        ID_MESSAGE++;
    }
    
    
}


