/* 
 *Funções que permitem trabalhar mais facilmente com o modelo SPA (Single Page Aplication)
 */

/**
 * TODO implementar possibilidade de não ter dados no tpl ser apenas uma view
 * 
 * @param {type} tpl
 * @param {type} div
 * @param {type} dados
 * @returns {undefined}
 */
function requireTpl(tpl, div, dados) {
    var tpl = _TPL_BASE + '/' + tpl + '.html';
    $.get(tpl,
        function(data) {
            str = data;
            for (var d in dados) {
                processa(dados[d], d);
            }
            $(div).html(str);
            $(div).removeClass('loading');
        }
    );

    function processa(obj, tipo) {
        for (var d in obj) {
            if ((typeof obj[d]) === "object") {
                processa(obj[d], d);
            }
            var pt = tipo + "." + d;
            var rg = new RegExp("{{" + pt + "}}", 'g');
            str = str.replace(rg, obj[d]);
        }
    }
}

/**
 * Função que permite requisitar um template para inserir em uma parte de uma página 
 * 
 * @deprecated Usar a versão requireAjaxTPL (url, elemento, dados) que funciona de forma assincrona
 * @param {type} url
 * @returns {data}
 */
function requireServerTPL(url) {
    var dataReturn;
    jQuery.ajaxSetup({ async: false });
    $.get(url,
        function(data) {
            dataReturn = data;
        }
    );
    jQuery.ajaxSetup({ async: true });
    // console.log(dataReturn);
    return dataReturn;
}

/**
 * Função que permite requisitar um template para inserir em uma parte de uma página através
 * de ajax e chamar um callback.
 * 
 * @param {seletor} elemento 
 * @param {string} url 
 * @param {function} callback - optional
 * @returns 
 */
function requireAjaxTPL(elemento, url, callback) {
    var dataReturn;
    $.get(url,
        function(data) {
            $(elemento).html(data);
            if (callback) {
                callback()
            }
        }
    );
    // console.log(dataReturn);
    return dataReturn;
}


function getJson(item) {
    item = typeof item !== "string" ?
        JSON.stringify(item) :
        item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return item;
    }

    return false;
}


function tpl(tpl, dados, objetoInicial) {
    var str = tpl;

    processa(dados, objetoInicial);

    function processa(obj, tipo) {
        for (var d in obj) {
            if ((typeof obj[d]) === "object") {
                processa(obj[d], d);
            }
            if (tipo) {
                var pt = tipo + "." + d;
            } else {
                var pt = d;
            }
            var rg = new RegExp("{{" + pt + "}}", 'g');
            str = str.replace(rg, obj[d]);
        }
    }

    return str;
}


function requireJS(js) {
    $('body').prepend('<script src="' + js + '"> </script>')
}


/**
 * Método que processa um template com um estilo Mustache bem simples. 
 * 
 * @param {*} tpl 
 * @param {*} dados 
 * @param {*} objetoInicial 
 * @returns 
 */
Eny.processaTPL = function(tpl, dados, objetoInicial) {
    var str = tpl;

    processa(dados, objetoInicial);

    function processa(obj, tipo) {
        for (var d in obj) {
            if ((typeof obj[d]) === "object") {
                processa(obj[d], d);
            }
            if (tipo) {
                var pt = tipo + "." + d;
            } else {
                var pt = d;
            }
            var rg = new RegExp("{{" + pt + "}}", 'g');
            str = str.replace(rg, obj[d]);
        }
    }

    return str;
}